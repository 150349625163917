import runes from 'runes';

export class Emojicrypt {
    encrypt(message, data, strict = true) {
        if (!data.password) {
            throw new Error('Password is required.');
        }
        let alphabet = runes(data.alphabet);
        let password = runes(data.password);
        let ciphertext = "";

        if (!strict) {
            password = password.filter(it => alphabet.includes(it));
            if (password.length === 0) {
                throw new Error('Password is required.');
            }
        }

        let i = 0;
        let charLower;
        let charIndex;
        let pwChar;
        let pwIndex;
        let cipherChar;
        for (let char of runes(message)) {
            charLower = char.toLowerCase();
            charIndex = alphabet.indexOf(charLower);
            if (charIndex === -1) {
                ciphertext += char;
                continue;
            }
            pwChar = password[i % password.length];
            pwIndex = alphabet.indexOf(pwChar);
            if (pwIndex === -1) {
                throw new Error(`Password character '${pwChar}' is not in the alphabet, cannot encrypt.`);
            }

            cipherChar = alphabet[(charIndex + pwIndex) % alphabet.length];

            if (charLower === char) {
                ciphertext += cipherChar;
            } else {
                ciphertext += cipherChar.toUpperCase();
            }
            ++i;
        }
        return ciphertext;
    }

    decrypt(ciphertext, data, strict = true) {
        if (!data.password) {
            throw new Error('Password is required.');
        }
        let alphabet = runes(data.alphabet);
        let password = runes(data.password);
        let plaintext = "";

        if (!strict) {
            password = password.filter(it => alphabet.includes(it));
            if (password.length === 0) {
                throw new Error('Password is required.');
            }
        }

        let i = 0;
        let char;
        let pwChar;
        let pwIndex;
        let cipherIndex;
        for (let cipherChar of runes(ciphertext)) {
            let cipherLower = cipherChar.toLowerCase();
            cipherIndex = alphabet.indexOf(cipherLower);
            if (cipherIndex === -1) {
                plaintext += cipherChar;
                continue;
            }
            pwChar = password[i % password.length];
            pwIndex = alphabet.indexOf(pwChar);
            if (pwIndex === -1) {
                throw new Error(`Password character '${pwChar}' is not in the alphabet, cannot decrypt.`);
            }

            char = alphabet[(cipherIndex + alphabet.length - pwIndex) % alphabet.length];

            if (cipherLower === cipherChar) {
                plaintext += char;
            } else {
                plaintext += char.toUpperCase();
            }
            ++i;
        }

        return plaintext;
    }
}
