<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Quick Cipher</span>
        </portal>

        <div class="row">
            <div class="col-md-6">
                <FormulateInput
                    type="email"
                    label="User Email"
                    help="Enter the email address of the user you want to decrypt from."
                    v-model="email"
                    validation="email|optional"
                    error-behavior="live"
                    @validation="emailValidation = $event"
                />
            </div>
            <div class="col-md-6">
                <ContractLoader
                    label="Select Token"
                    hide-recents
                    features="erc20"
                    :load-click="addressLoaded"
                    @reset="address = null"
                />
            </div>
        </div>

        <div v-if="!useEmailAlphabet && loggedIn" class="self-alphabet">
            Using your own alphabet from <router-link
            to="/app/account/emojicryption">Emojicryption Settings</router-link>,
            fill in the fields above to use someone else's alphabet.
        </div>
        <div v-else-if="!useEmailAlphabet" class="self-alphabet">
            Using a standard alphabet.
        </div>

        <div class="form-group">
            <label>Plain Text</label>
            <textarea class="form-control" name="plaintext" v-model="plainText"
                      @input="plainInput"></textarea>
            <small class="form-text text-muted">
                Type or paste here to encrypt.
            </small>
        </div>


        <div class="form-group">
            <label>Password</label>
            <input type="text" class="form-control" name="pass" autocapitalize="none"
                   @input="passwordInput" autocomplete="off">
            <div v-if="passwordError" class="invalid-feedback">
                {{passwordError}}
            </div>
            <small class="form-text text-muted">
                Enter the password to encrypt and decrypt with.
            </small>
        </div>

        <div class="form-group">
            <div class="ciphertext-label">
                <label>Ciphertext</label>
                <Clipboard :clipboard="cipherText" link-only/>
                <a href="javascript: void 0" class="ciphertext-share" @click="share" v-if="canShare">Share</a>
            </div>
            <textarea class="form-control" name="ciphertext" v-model="cipherText"
                      @input="cipherInput"></textarea>
            <small class="form-text text-muted">
                Type or paste here to decrypt.
            </small>
        </div>
    </layout-heading>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import {Emojicrypt} from "@/views/happs/book/encryption/Emojicrypt";
import ContractLoader from "@/views/happs/components/ContractLoader";
import {mapGetters} from "vuex";

const crypt = new Emojicrypt();

export default {
    components: {Clipboard, ContractLoader},
    data() {
        return {
            plainText: "",
            cipherText: "",
            address: null,
            email: null,
            password: "",
            selfAlphabet: null,
            decrypting: null,
            emailValidation: {}
        };
    },
    methods: {
        resizeBoxes() {
            this.$nextTick(() => {
                $('textarea', this.$el).each(function () {
                    // Need to set the wrapper's height to prevent scrolling the screen
                    // when we adjust the textarea height
                    let wrap = this.parentElement;
                    wrap.style = `height: ${wrap.scrollHeight}px`;
                    this.style = "height: auto;";
                    if (this.scrollHeight > 0) {
                        this.style = `height: ${this.scrollHeight + 5}px`;
                    }
                    wrap.style = '';
                });
            });
        },
        plainInput(event) {
            this.decrypting = false;
            this.cipherText = this.encrypt(event.target.value);
            this.resizeBoxes();
        },
        cipherInput(event) {
            this.decrypting = true;
            this.plainText = this.decrypt(event.target.value);
            this.resizeBoxes();
        },
        passwordInput(event) {
            this.password = event.target.value;
            if (this.decrypting === true) {
                this.plainText = this.decrypt(this.cipherText);
            } else if (this.decrypting === false) {
                this.cipherText = this.encrypt(this.plainText);
            }
            return true;
        },
        encrypt(text) {
            if (this.password) {
                return crypt.encrypt(text, {
                    password: this.password,
                    alphabet: this.alphabet
                }, false);
            }
            return null;
        },
        decrypt(ciphertext) {
            if (this.password) {
                return crypt.decrypt(ciphertext, {
                    password: this.password,
                    alphabet: this.alphabet
                }, false);
            }
            return null;
        },
        addressLoaded(data) {
            this.address = data.address;
        },
        share() {
            navigator.share({
                text: this.cipherText
            });
        }
    },
    computed: {
        ...mapGetters('user', ['api', 'loggedIn']),
        useEmailAlphabet() {
            return !!this.address && !!this.email;
        },
        alphabet() {
            if (this.emailAlphabet) {
                return this.emailAlphabet.alphabet;
            }
            return this.selfAlphabet || ":!.,-_();😭🐑🐗🐧🎃👻🎄abcdefghijklmnopqrstuvwxyz0123456789";
        },
        canShare() {
            return !!navigator.share;
        },
        passwordError() {

        }
    },
    asyncComputed: {
        emailAlphabet() {
            if (this.emailValidation.hasErrors === false && this.email && this.address) {
                return this.api.getEmojicryptionForEmail(this.email, this.address);
            }
            return null;
        }
    },
    watch: {
        alphabet() {
            this.passwordInput();
        }
    },
    created() {
        if (this.loggedIn) {
            this.api.getEmojicryptionSettings()
                .then(res => {
                    if (res.alphabet) {
                        this.selfAlphabet = res.alphabet;
                    }
                })
                .catch(console.error);
        }
    },
    mounted() {
        this.resizeBoxes();
    }
}
</script>

<style scoped lang="scss">
.self-alphabet {
    font-size: 18px;
    margin-bottom: 15px;
}

.ciphertext-label {
    display: flex;
    align-items: center;
    margin-bottom: .25rem;
    ::v-deep( label ) {
        margin: 0;
    }
    ::v-deep( a ) {
        margin-left: 15px;
    }
}
</style>
